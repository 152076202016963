import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import get from "lodash/get";
import { Type } from "../../Typography";
import { colors } from "../../../../vars/palette";
import { WmkLink } from "wmk-link";
import Share from "../../../Layout/Header/MainBar/Share";
import { NewsArticle } from "../../../../classes/News";

const Wrap = styled.div`
  * {
  }
`;
const Card = styled.div`
  width: 16rem !important;
  height: 18rem;
  box-shadow: 0px 0px 10px #00000030;
  padding: 1rem;
  margin: 1rem 0;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  img {
    width: 100%;
    max-height: 9rem;
    object-fit: cover;
    object-position: center 15%;
    margin: 0 auto 0.75rem;
    border-radius: 5px;
  }
  a {
    text-decoration: none;
  }
`;
const ShareWrap = styled.div`
  position: relative;
  bottom: 3rem;
  right: -5.5rem;
  button {
    background: none;
  }
  a {
    color: ${colors.black.hex} !important;
  }
`;

const NewsSlide = ({
  article,
  sliderType = ""
}: {
  article: NewsArticle;
  sliderType?: string;
}) => {

  const [shareOpen, setShareOpen] = useState(false);
  const toggleOpen = () => setShareOpen(!shareOpen);
  const title = article.title;
  const link = article.url;
  const slug = article.slug;
  const imageUrl = article.image.src ? article.image.src : article.image.url();
  const date = new Date(article.date).toString();
  const formattedDate = date.slice(3, 10) + "," + date.slice(10, 15);
  const source = article.contentSource;
  return (
    <Wrap>
      <Card>
        <WmkLink
          to={link ? link : `/news/news-and-commentary/${slug}`}
          target={source === "xml" ? "blank" : undefined}>
          {imageUrl ? <img src={imageUrl} alt={title} /> : null}
          <Type.SubTitle
            style={{
              textTransform: "uppercase",
              fontSize: ".8rem",
              fontWeight: "bold"
            }}>
            {title}
          </Type.SubTitle>
          <Type.P
            style={{
              fontSize: ".7rem",
              fontWeight: "bold",
              color: colors.green.hex
            }}>
            {formattedDate}
          </Type.P>
        </WmkLink>
        <ShareWrap>
          <Share
            toggle={toggleOpen}
            open={shareOpen}
            shareUrl={
              link
                ? link
                : slug && sliderType !== "cision"
                ? `https://dipra.org/news/news-and-commentary/${slug}`
                : null
            }
          />
        </ShareWrap>
      </Card>
    </Wrap>
  );
};

export default NewsSlide;
