import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get } from "lodash";
import styled from "styled-components";
import { colors } from "../../vars/palette";
import { Type } from "./Typography";
import DipraButton from "./DipraButton";
import { WmkLink } from "wmk-link";
import { Img, WmkImage } from "wmk-image";
// import { WmkLink } from "wmk-link";

const StyledNewsHeader = styled.div`
  height: 25rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .date,
  .author,
  .publication {
    color: ${colors.white.hex};
  }
`;
const BgWrap = styled.div`
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    filter: brightness(75%) grayscale(50%);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 25%;
    }
  }
  .blue-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.darkBlue.hex};
    opacity: 0.5;
  }
`;

/**
 * Renders image + text + cta header for internal news article pages
 * @param {object} image
 * @param {string} headline
 * @param {string} date
 * @param {string} author
 * @param {string} publication
 * @returns
 */
const NewsHeader = ({
  image,
  headline,
  date,
  author,
  publication
}: {
  image: Img;
  headline: string;
  date: string;
  author: string;
  publication: string;
}) => {
  return (
    <StyledNewsHeader>
      <BgWrap>
        {/* <GatsbyImage image={gatsby} alt={alt} /> */}
        <WmkImage image={image}/>
        <div className="blue-overlay" />
      </BgWrap>
      <Container>
        <Row className="text-center ">
          <Col>
            <WmkLink to="/news/news-and-commentary">
              <Type.NewsSubhead>News</Type.NewsSubhead>
            </WmkLink>
            <Type.NewsHeadline>{headline}</Type.NewsHeadline>
            <div className="date">{date}</div>
            <div className="author">{author}</div>
            <div className="publication">{publication}</div>
            <DipraButton
              link={{
                to: "/news/dipra-e-newsletters/subscribe",
                target: "_self",
                text: "JOIN OUR MAILING LIST"
              }}
              color={colors.green.hex}
              textColor={colors.white.hex}
              fontSize={`17px`}
              bold
              hoverColor={colors.blue.hex}
              innerPadding=".25rem 1rem"
              style={{ marginTop: "2rem" }}
            />
          </Col>
        </Row>
      </Container>
    </StyledNewsHeader>
  );
};

export default NewsHeader;
