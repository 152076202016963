import get from "lodash/get";
import { NewsQuery } from "../fragments/NodeNewsFields";

import { slugifyTitle, Tag } from "./Tag";
import { RichText } from "wmk-rich-text";
import { Img } from "wmk-image";

const getHashTable = (obj, param) =>
  obj && obj.length
    ? obj.reduce((hash, p) => {
        const slug = get(p, param);
        if (typeof slug !== "undefined") {
          hash[slug] = p;
        }
        return hash;
      }, {})
    : {};

export interface CisionNewsData {
  Contact_Name: {};
  News_Article_Image_URL: string;
  News_Attachment_Name: string;
  News_Date: string;
  News_Headline: string;
  News_ID: string;
  News_Text: string;
  Outlet_Name: string;
}

export class NewsArticle {
  title: string;
  author: string;
  date: Date;
  tags: Tag[];
  link: string;
  slug: string;
  image: Img;
  contentSource: string;
  content: string | RichText;
  quote: string;
  source: string;
  publication: string;
  url: string;
  meta: string;
  constructor(node: NewsQuery | CisionNewsData) {
    const contentSource =
      "databaseId" in node ? "wp" : "News_ID" in node ? "xml" : "ctfl";
    const title =
      "title" in node
        ? node.title
        : node.News_Headline
        ? node.News_Headline
        : "";
    const tags = "tags" in node ? node.tags : title.split(" ");
    const image = new Img(
      "featuredImage" in node
        ? node.featuredImage
        : { file: { url: node.News_Article_Image_URL }, title }
    );
    const link =
      "News_Attachment_Name" in node ? node.News_Attachment_Name : node.source;
    const slug = "slug" in node ? node.slug : slugifyTitle(title);
    const author = "author" in node ? node.author : node.Contact_Name;
    const publication =
      "publication" in node ? node.publication : node.Outlet_Name;
    const _content = "content" in node ? node.content : node.News_Text;
    const content =
      typeof _content === "string" ? _content : new RichText(_content);
    const meta =
      content instanceof RichText
        ? content.excerpt(155)
        : "pull" in node
        ? node.pull.quote
        : "";
    const _url =
      "News_Attachment_Name" in node ? node.News_Attachment_Name : undefined;
    const url = typeof _url === "string" ? _url : undefined;
    this.title = title;
    this.author = typeof author === "string" ? author : publication;
    this.date = new Date("date" in node ? node.date : node.News_Date);
    this.tags = Array.isArray(tags) ? tags.map((t) => new Tag(t)) : [];
    this.link = link;
    this.slug = slug;
    this.image = image ? image : null;
    // This will be modified to add an identifier for Cision XML feed
    this.contentSource = contentSource;
    this.content = content;
    this.quote = "pull" in node ? node.pull?.quote : undefined;
    this.source =
      "source" in node ? node.source : "mailto:" + node.Contact_Name;
    this.publication = publication;
    this.url = url;
    this.meta = meta;
  }
}

export class News {
  list: NewsArticle[];
  length: number;
  tags: string;
  slugHash: NewsArticle[];
  constructor(edges) {
    // get raw blog data
    const rawBlogs = Array.isArray(edges)
      ? edges.map((e) => new NewsArticle(e.node))
      : [];
    // overwrite slug conflicts with CTFL data
    const merged = rawBlogs.reduce((merge, blog) => {
      merge[blog.slug] = blog;
      return merge;
    }, {});
    // turn merged data back into array
    const blogs = Object.keys(merged).map((key) => merged[key]);
    // create hash table for each category slug
    const catHash = blogs.reduce((hash, blog) => {
      const tags = get(blog, `tags`, []);
      tags.forEach((tag) => {
        if (!hash[tag.slug]) {
          hash[tag.slug] = { ...tag, list: [] };
        }
        hash[tag.slug].list.push(blog);
      });
      return hash;
    }, {});
    this.list = blogs;
    this.length = blogs.length;
    this.tags = catHash;
    this.slugHash = getHashTable(blogs, `slug`);
  }
  getTag(tagSlug) {
    return get(this, `categories[${tagSlug}]`);
  }
  getSlug(slug) {
    return get(this, `slugHash[${slug}]`);
  }
}
