import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import NewsHeader from "../components/ui/NewsHeader";
import { NewsArticle } from "../classes/News";
import DipraNewsSlider from "../components/ui/News/NewsSliders/DipraNewsSlider";
import { Type } from "../components/ui/Typography";
import { WmkLink } from "wmk-link";
import { WmkSeo } from "wmk-seo";
import { NewsQuery } from "../fragments/NodeNewsFields";
import { RichTextReact } from "wmk-rich-text";
import { options } from "../components/ui/RichText/richTextOptions";

const Wrap = styled.div`
  padding: 2rem 5%;
`;
const CopyRow = styled(Row)`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const ImageWrap = styled.div`
  .gatsby-image-wrapper {
    margin-left: 2rem;
  }
`;

const NewsNode = ({
  data
}: {
  data: {
    article: NewsQuery;
    site: {
      siteMetadata: {
        baseUrl: string;
        title: string;
        twitter: string;
      };
    };
  };
}) => {
  const article = new NewsArticle(data.article);
  const title = article.title;
  const author = article.author;
  const content = article.content;
  const date = article.date;
  const image = article.image;
  const source = article.source;
  const publication = article.publication;
  const quote = article.quote;

  // format date
  const newDate = new Date(date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const month = months[newDate.getUTCMonth()];
  const day = newDate.getUTCDate();
  const year = newDate.getUTCFullYear();
  const formattedDate = `${month} ${day}, ${year}`;
  return (
    <>
      <WmkSeo.Meta
        title={article.title}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug={article.slug}
        path={"/news/news-and-commentary/"}
        description={article.meta}
        ogImageUrl={article.image.url() ? article.image.url() : ""}
        twitterImageUrl={article.image.url() ? article.image.url() : ""}
        twitterHandle={data.site.siteMetadata.twitter}
      />
      <Wrap>
        <NewsHeader
          image={image}
          headline={title}
          date={formattedDate}
          author={author}
          publication={publication}
        />
        <Container fluid>
          <CopyRow>
            <Col lg={quote ? 4 : 0}>
              <Type.Quote>{quote}</Type.Quote>
            </Col>
            <Col lg={quote ? 8 : 12}>
              {typeof content === "string" ? (
                <>{content}</>
              ) : (
                <ImageWrap>
                  <RichTextReact content={content} options={options} />
                </ImageWrap>
              )}
              {source && publication ? (
                <Row style={{ padding: "0 32px" }}>
                  <Col xs="auto">
                    <Type.P>SOURCE: </Type.P>
                  </Col>
                  <Col style={{ padding: 0 }}>
                    <Type.P>
                      <WmkLink to={source} target="blank">
                        {publication}
                      </WmkLink>
                    </Type.P>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </CopyRow>

          <Row className="flex-column">
            <Col>
              <DipraNewsSlider title="In The News" />
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </Wrap>
    </>
  );
};

export default NewsNode;

export const query = graphql`
  query newsQuery($slug: String) {
    article: contentfulNews(slug: { eq: $slug }) {
      ...NodeNewsFields
    }
    site {
      siteMetadata {
        baseUrl
        title
        twitter
      }
    }
  }
`;
